/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Layout from 'components/layout/Layout'
import NavLink from 'components/shared/NavLink'

// Images
import Arrow from 'img/arrow.inline.svg'

// Third Party
import Levenshtein from 'js-levenshtein'

interface Props {
  location: {
    pathname: string
  }
}

type LevenShteinList = Array<{
  uri: string
  title: string
  score: number
}>

const NotFoundPage: React.FC<Props> = ({ location }) => {
  // @ts-ignore
  const query = useStaticQuery<GatsbyTypes.notfoundQuery>(graphql`
    query notfound {
      allWpPage {
        nodes {
          title
          uri
        }
      }
    }
  `)

  const [list, setList] = React.useState<LevenShteinList>([])

  React.useEffect(() => {
    const levenshteinList: LevenShteinList = []

    query.allWpPage.nodes.forEach((n: any) => {
      levenshteinList.push({
        uri: n.uri,
        title: n.title,
        score: Levenshtein(location.pathname, n.uri),
      })
    })

    setList(levenshteinList)
  }, [])

  return (
    <Layout>
      <div className="py-lg-5 container py-3">
        <div className="grid grid-cols-5 sm:place-items-center">
          <div className="col-span-5">
            <div
              className="rounded-3xl py-5 sm:px-8 sm:py-10 lg:py-20"
              style={{ boxShadow: '0 0 4px rgba(0, 0, 0, 0.3);' }}
            >
              <h1 className="text-center font-primary text-lg font-bold text-secondary">
                404 ERROR
              </h1>
              <h2 className="text-center text-4xl font-medium sm:text-6xl">
                Deze pagina bestaat niet.
              </h2>
              <div className="lg:marker:py-3">
                <p className="mb-0 text-center font-secondary text-lg font-light opacity-50 sm:text-xl">
                  De pagina die je probeert te bekijken bestaat niet.
                </p>
              </div>
            </div>
          </div>
          <div className="relative col-span-5 pt-10">
            <div className="w-full sm:min-w-[400px] lg:min-w-[600px]">
              <h2 className="mb-0 font-secondary text-sm font-medium uppercase opacity-50">
                Populaire paginas
              </h2>
              <div className="w-full">
                {list
                  .sort((a, b) => a.score - b.score)
                  .slice(0, 5)
                  .map((n, index: number) => (
                    <NavLink
                      to={n.uri}
                      className="group flex cursor-pointer items-center justify-between border-t-[1px] border-solid border-primary border-opacity-20 px-2 py-2 last:border-b-[1px] hover:underline"
                      key={index}
                    >
                      <div className="flex w-full items-center justify-between rounded-lg px-4 py-2 transition-all duration-200 group-hover:bg-grey">
                        <div className="font-secondary text-xl">{n.title}</div>
                        <Arrow className="children-path:fill-primary children-path:transition-all children-path:duration-200 group-hover:children-path:fill-secondary" />
                      </div>
                    </NavLink>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
